import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = true;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Alya Latifa';
export const GIRL_NAME_SHORT = 'Alya';
export const GIRL_FATHER_NAME = `Arief Noor Raharjo`;
export const GIRL_MOTHER_NAME = `Widyorini`;
export const GIRL_PARENT_NAME = {
  en: `The daughter of Mr. ${GIRL_FATHER_NAME}<br /> and Mrs. ${GIRL_MOTHER_NAME}`,
  id: `Putri dari Bapak ${GIRL_FATHER_NAME}<br /> dan Ibu ${GIRL_MOTHER_NAME}`,
};
// -> boy section
export const BOY_NAME = 'Firhan Ulinuha Fachriansyah';
export const BOY_NAME_SHORT = 'Firhan';
export const BOY_FATHER_NAME = `Muskan Jumaeri`;
export const BOY_MOTHER_NAME = `Ninik Kristinah`;
export const BOY_PARENT_NAME = {
  en: `The son of Mr. ${BOY_FATHER_NAME}<br /> and Mrs. ${BOY_MOTHER_NAME}`,
  id: `Putra dari Bapak ${BOY_FATHER_NAME}<br /> dan Ibu ${BOY_MOTHER_NAME}`,
};
// -> bride section
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'firrrhan';
export const IG_GIRL = 'alyalatifa21';

//Instagram Filter
export const IG_FILTER = 'https://www.instagram.com/ar/2338985746273419/';
export const IMG_IG_FILTER =
  'https://ik.imagekit.io/adelinejune/firhanalya/COVER-FIRHAN-ALYA.jpg?updatedAt=1697780874992';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE =
  'https://ik.imagekit.io/adelinejune/firhanalya/SEO?updatedAt=1697175652940';
export const SEO_URL = 'https://invitato.net/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato.net`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1699747200;
export const EPOCH_RESEPSI_START = 1699761600;
export const EPOCH_END_EVENT = 1699768800;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2023-11-12');
export const DATE_RESEPSI = new Date('2023-11-12');

export const WEDDING_AKAD_TIME = '07.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Gedung Sasana Widya Praja BPSDMD Prov. Jateng';
export const WEDDING_AKAD_LOC_ROAD = `JL. Setiabudi No. 201 A Srondol Kulon, Kec. Banyumanik, Semarang, Jawa Tengah 50263`;
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');

export const WEDDING_RESEPSI_TIME = '11.00 - 13.00 WIB';
export const WEDDING_RESEPSI_LOC_NAME = `Gedung Sasana Widya Praja BPSDMD Prov. Jateng`;
export const WEDDING_RESEPSI_LOC_ROAD = WEDDING_AKAD_LOC_ROAD;
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd, DD MMMM YYYY');

export const WEDDING_DATE = dayjs(DATE_RESEPSI).locale(DEFAULT_LANGUAGE).format('DD • MM • YYYY');
/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://FirhanAlyaWedding.com/';
export const BROADCAST_WEDDING_LOCATION = WEDDING_RESEPSI_LOC_NAME;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/pFEeEfRW5od79Yoc8`;
export const GOOGLE_MAPS_ADDRESS = WEDDING_AKAD_LOC_NAME;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Turning Page - Sleeping At Last`;
export const SOUND_URL = 'https://youtu.be/-hb2tecD13s?si=-XqzqiGpFFVvdFYX';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://youtu.be/01uFbBsjJ6g?si=NE5Cu8g741SAmCan';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/01uFbBsjJ6g?si=PkhSn-kryJMfuU04';
export const YOUTUBE_PREWEDDING_EMBED = `https://www.youtube.com/embed/01uFbBsjJ6g?si=PkhSn-kryJMfuU04`;
export const YOUTUBE_PREWEDDING_LINK = `https://youtu.be/01uFbBsjJ6g?si=NE5Cu8g741SAmCan`;

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbxFghsHXH_A5PCytIITP2kAHFRgNmUoQWOI5383ageLn_h_k97qaCeQnXq1qZxT7PHR/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO =
  'https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';
